<template>
  <div>
    <footer class="footer-style-2-area footer-style-3-area">
      <div class="container custom-container">
        <div class="row">
          <div class="col-lg-4">
            <div class="widget widget-about">
              <a href="#"><img src="@/assets/images/logo-4.png" alt="" /></a>
              <p>
                <span>Newspark</span> vitae elementum ros. Pellentesquees Leo
                sed mi ullamcorper tristique mauris varius. congue enim in arcu
                maximus feugiat vitae ut quam finibus risus molestie viverra.
              </p>
            </div>
            <div class="widget widget-services mt-35">
              <div class="section-title section-title-3">
                <h3 class="title">Newspark news services</h3>
              </div>
              <div class="service-item">
                <ul>
                  <li>
                    <a href="#"
                      ><i class="fal fa-mobile-android-alt"></i> On your
                      mobile</a
                    >
                  </li>
                  <li>
                    <a href="#"
                      ><i class="fal fa-microphone-alt"></i> On smart
                      speakers</a
                    >
                  </li>
                  <li>
                    <a href="#"
                      ><i class="fal fa-envelope"></i> Contact Newspark news</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="widget widget-news-categories">
              <div class="section-title section-title-3">
                <h3 class="title">News categories</h3>
              </div>
              <div class="categories-list d-flex">
                <ul class="pr-80">
                  <li><a href="#">Politics</a></li>
                  <li><a href="#">Business</a></li>
                  <li><a href="#">Technology</a></li>
                  <li><a href="#">Science</a></li>
                  <li><a href="#">Health</a></li>
                  <li><a href="#">Sports</a></li>
                  <li><a href="#">Entertainment</a></li>
                </ul>
                <ul>
                  <li><a href="#">Education</a></li>
                  <li><a href="#">Obituaries</a></li>
                  <li><a href="#">Corrections</a></li>
                  <li><a href="#">Education</a></li>
                  <li><a href="#">Today’s Paper</a></li>
                  <li><a href="#">Corrections</a></li>
                  <li><a href="#">Foods</a></li>
                </ul>
              </div>
            </div>
            <div class="widget widget-twitter">
              <div class="section-title section-title-3">
                <h3 class="title">Twitter feed</h3>
              </div>
              <div class="twitter-post">
                <div class="icon">
                  <i class="fab fa-twitter"></i>
                </div>
                <div class="twitter-content">
                  <p>
                    Cyber Monday Sale, Save 33% on Jannah theme during our
                    year-end Sale, Purchase a new license for your next project…
                    <a href="#"
                      >@newspark #technology
                      https://dribbble.com/subash_chandra</a
                    >
                  </p>
                  <span>March 26, 2020</span>
                </div>
              </div>
              <div class="twitter-post">
                <div class="icon">
                  <i class="fab fa-twitter"></i>
                </div>
                <div class="twitter-content">
                  <p>
                    Cyber Monday Sale, Save 33% on Jannah theme during our
                    year-end Sale, Purchase a new license for your next project…
                    <a href="#"
                      >@newspark #technology
                      https://dribbble.com/subash_chandra</a
                    >
                  </p>
                  <span>March 26, 2020</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="footer-top-info">
              <div class="row">
                <div class="col-md-4">
                  <div class="footer-info-item">
                    <h3 class="title">Let’s talk</h3>
                    <ul>
                      <li><a href="#">+41 27 966 26690</a></li>
                      <li><a href="#">+880 1945 381758</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="footer-info-item">
                    <h3 class="title">Let’s chat</h3>
                    <ul>
                      <li><a href="#">hello@newspark.com</a></li>
                      <li><a href="#">adsales@newspark.com</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="footer-info-item">
                    <h3 class="title">Headquarters</h3>
                    <ul>
                      <li><a href="#">44 Canal Center Plaza #200 </a></li>
                      <li><a href="#">Alexandria, VA 22314, USA</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-center-box mt-40">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="widget widget-more-news">
                    <div class="section-title section-title-3">
                      <h3 class="title">More news</h3>
                    </div>
                    <div class="more-news-items">
                      <div class="more-news-item">
                        <div class="meta-categories">
                          <span>TECHNOLOGY</span>
                        </div>
                        <h3 class="title">
                          <a href="#"
                            >Japan’s virus success puzzled the world luck
                            running out?</a
                          >
                        </h3>
                        <p>
                          The property, complete with seates screening from room
                          amphitheater pond with sandy shower…
                        </p>
                        <ul>
                          <li>
                            <a href="#"><i class="far fa-eye"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="far fa-heart"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="fas fa-share"></i> 53,346</a>
                          </li>
                        </ul>
                      </div>
                      <div class="more-news-item">
                        <div class="meta-categories">
                          <span>TECHNOLOGY</span>
                        </div>
                        <h3 class="title">
                          <a href="#"
                            >Japan’s virus success puzzled the world luck
                            running out?</a
                          >
                        </h3>
                        <p>
                          The property, complete with seates screening from room
                          amphitheater pond with sandy shower…
                        </p>
                        <ul>
                          <li>
                            <a href="#"><i class="far fa-eye"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="far fa-heart"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="fas fa-share"></i> 53,346</a>
                          </li>
                        </ul>
                      </div>
                      <div class="more-news-item">
                        <div class="meta-categories">
                          <span>TECHNOLOGY</span>
                        </div>
                        <h3 class="title">
                          <a href="#"
                            >Japan’s virus success puzzled the world luck
                            running out?</a
                          >
                        </h3>
                        <p>
                          The property, complete with seates screening from room
                          amphitheater pond with sandy shower…
                        </p>
                        <ul>
                          <li>
                            <a href="#"><i class="far fa-eye"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="far fa-heart"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="fas fa-share"></i> 53,346</a>
                          </li>
                        </ul>
                      </div>
                      <div class="more-news-item">
                        <div class="meta-categories">
                          <span>TECHNOLOGY</span>
                        </div>
                        <h3 class="title">
                          <a href="#"
                            >Japan’s virus success puzzled the world luck
                            running out?</a
                          >
                        </h3>
                        <p>
                          The property, complete with seates screening from room
                          amphitheater pond with sandy shower…
                        </p>
                        <ul>
                          <li>
                            <a href="#"><i class="far fa-eye"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="far fa-heart"></i> 53,346</a>
                          </li>
                          <li>
                            <a href="#"><i class="fas fa-share"></i> 53,346</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="widget widet-newsletter">
                    <div class="post-newsletter">
                      <div class="section-title section-title-3">
                        <h3 class="title">Newsletter</h3>
                      </div>
                      <p>
                        Your email address will not be this published. Required
                        fields are News Today.
                      </p>
                      <form action="#">
                        <div class="input-box">
                          <input type="text" placeholder="Your email address" />
                          <button type="button">SIGN UP</button>
                        </div>
                      </form>
                      <span>We hate spam as much as you do</span>
                    </div>
                  </div>
                  <div class="weidget widget-social">
                    <div class="post-contact mt-40">
                      <div class="section-title section-title-3">
                        <h3 class="title">Connect us</h3>
                      </div>
                      <ul>
                        <li>
                          <a href="#">
                            <i class="fab fa-facebook-f"></i>
                            <span>34,456</span>
                            <p>Fans</p>
                          </a>
                        </li>
                        <li>
                          <a class="item-2" href="#">
                            <i class="fab fa-twitter"></i>
                            <span>34,456</span>
                            <p>Followers</p>
                          </a>
                        </li>
                        <li>
                          <a class="item-3" href="#">
                            <i class="fab fa-youtube"></i>
                            <span>34,456</span>
                            <p>Subscribers</p>
                          </a>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <a class="item-4" href="#">
                            <i class="fab fa-instagram"></i>
                            <span>34,456</span>
                            <p>Followers</p>
                          </a>
                        </li>
                        <li>
                          <a class="item-5" href="#">
                            <i class="fab fa-vimeo-v"></i>
                            <span>34,456</span>
                            <p>Followers</p>
                          </a>
                        </li>
                        <li>
                          <a class="item-6" href="#">
                            <i class="fab fa-maxcdn"></i>
                            <span>34,456</span>
                            <p>Fans</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="weidget weidget-ad mt-30">
                    <div class="ad">
                      <a href="#"
                        ><img src="assets/images/ad/ad-3.png" alt=""
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                footer-download-store
                d-block d-md-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="text">
                <h4 class="title">Newspark app download</h4>
                <span>Free sign & download, iOS & Android app</span>
              </div>
              <div class="store-btn">
                <ul>
                  <li>
                    <a href="#">
                      <i class="fab fa-google-play"></i>
                      <p>Download on the</p>
                      <span>GOOGLE PLAY</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fab fa-apple"></i>
                      <p>Download on the</p>
                      <span>APP STORE</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div
      class="footer-copyright footer-copyright-style-2 footer-copyright-style-3"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div
              class="
                copyright-item
                d-block d-md-flex
                justify-content-between
                align-items-center
              "
            >
              <p>© Copyright 2020, All Rights Reserved</p>
              <ul>
                <li><a href="#">About</a></li>
                <li><a href="#">Advertise</a></li>
                <li><a href="#">Privacy & Policy</a></li>
                <li><a href="#">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>