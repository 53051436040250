<template>
  <div>
    <home-one />
  </div>
</template>

<script>
import HomeOne from "../components/Home/index";
export default {
  components: { HomeOne },
};
</script>

<style>
</style>