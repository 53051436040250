<!-- https://www.the-koi.com/projects/a-step-by-step-guide-on-integrating-google-adsense-into-vout-vue-project/ -->

<!-- src/components/AdSense/AdSenseSquare1.vue -->
<template>
  <div>
    <ins class="adsbygoogle"
         style="display:block"
         data-ad-client="ca-pub-6542516428032881"
         data-ad-slot="2787127353"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
  </div>
</template>

<script>
export default {
  name: 'AdSenseSquare1',
  mounted() {
    // Check if the AdSense script is already loaded
    if (!window.adsbygoogle) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      script.setAttribute('data-ad-client', 'ca-pub-6542516428032881');
      script.crossOrigin = 'anonymous';
      script.onload = () => {
        (adsbygoogle = window.adsbygoogle || []).push({});
      };
      document.head.appendChild(script);
    } else {
      (adsbygoogle = window.adsbygoogle || []).push({});
    }
  },
};
</script>

<style scoped>
/* Add any styles for the AdSenseSquare1 component here */
</style>