<template>
    <div>
      <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
      <Header @toggleSidebar="toggleSidebar" />
      <!--====== POST LAYOUT 1 PART START ======-->

      <!--v-for_1/2-->
      <div v-for="item in filteredBigVideoCards" :key="item._id">

      
        


      <section class="post-layout-1-area pb-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="post-layout-top-content-2">
                <div
                  class="
                    post-categories
                    d-flex
                    justify-content-between
                    align-content-center
                  "
                >
                </div>
                <div class="post-content">
                  <h3 class="title">
                    {{item.title}} 
                  </h3>
                  <p> 
                    {{item.description}}
                  </p>
                  
                  <div class="p_image_w_txt" @click="redirectToLink(item.aff_link)">
                    <a :href="item.aff_link" class="thumb" target="_blank">
                      <img :src="require(`@/assets/images/${item.picture}`)" alt="" class="p_image"/>
                    </a>
                    <div class="redirect_txt">
                      <div class="text">
                        <a>Click Redirect to Amazon</a>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="post-author">
                  <div class="author-info">
                    <div class="thumb">
                      <img src="@/assets/images/ai_author/Nova_Quill.webp" alt="" class=""/>
                      
                    </div>

                    

                    <h5 class="title">Nova Quill</h5>
                    <!-- <ul>
                      <li>Updated May 28, 2024</li>
                    </ul> -->
                  </div>
                  <div class="author-social">
                    

                        <a >(Click Image To Redirect to Amazon Link)</a>


                  </div>
                </div>

                <!--v-for_2/2-->
                <div v-for="section in item.sections" :key="section.subtitle" class="post-text mt-30">
                  
                  <h2>
                    {{section.subtitle}}
                  </h2>
                  <p v-html="getParagraphContent(section)"></p>

                  <div class="session-img" v-if="section.pic">
                    <img
                        :src="require(`@/assets/images/${section.pic}`)"
                        alt=""
                    />
                  </div>
                  <!--refer to another page
                  <ul>
                    <li><a href="#">Should more of us wear face masks?</a></li>
                    <li>
                      <a href="#"
                        ><u
                          >Why some countries wear face masks and others don’t</u
                        ></a
                      >
                    </li>
                    <li>
                      <a href="#">Coronavirus: Are homemade face masks safe?</a>
                    </li>
                  </ul>
                  -->

                  
                </div>

                <br>

                <!--aff_link-->
                <div>
                  For more details, you can check the product on 
                  <a :href="item.aff_link" target="_blank" rel="noopener noreferrer" class="aff_link">
                    {{ item.aff_link }}
                  </a>
                </div>

                <br>

                <div>
                  <a>
                    #Tag :
                  </a>
                  
                    
                  <span v-for="(tag, index) in item.category.tag" :key="index">
      {{ tag }}<span v-if="index < item.category.tag.length - 1">, </span>
    </span>
                    
                    
                  
                </div>
                
                
                
                
            

              
              <!--end of v-for 2/2-->
                
              </div>
            </div>
            <div class="col-lg-4">
              <style-one :datas="Posts" />
            </div>
          </div>
        </div>
      </section>

    </div>
    <!--end for loop-->










      <!--====== POST LAYOUT 1 PART ENDS ======-->
  
      <!--====== LATEST NEWS PART START ======-->
  
      <our-latest-news :Posts="Posts" />
  
      <!--====== LATEST NEWS PART ENDS ======-->
  
      <!--====== POST FORM PART START ======-->
  
     
  
      <!--====== POST FORM PART ENDS ======-->
  
      <!--====== POST COMMENTS PART START ======-->
  
     
      <!--====== POST COMMENTS PART ENDS ======-->
  
      <!--====== ADD PART ENDS ======-->

      <div class="add-area text-center">
        <a href="#">
          <img src="@/assets/images/ai_author/ai_image/image_02.jpg" alt="" />
        </a>
      </div>
      
      <!--====== ADD PART ENDS ======-->
      
      <footer-one />
      <div class="go-top-area">
        <div class="go-top-wrap">
          <div class="go-top-btn-wrap">
            <a href="#" class="go-top go-top-btn d-block">
              <i class="fa fa-angle-double-up"></i>
              <i class="fa fa-angle-double-up"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Header from "../Utility/Header/index";
  import StyleOne from "../Utility/Sidebar/StyleOne.vue";
  import Posts from "../Data/NewsRowCard";
  import FooterOne from "../Utility/Footer/FooterOne.vue";
  import OurLatestNews from "../Utility/Common/OurLatestNews.vue";
  import Drawer from "../Mobile/Drawer.vue";
  import BigVideoCardWithDes from "../Data/BigVideoCardWithDes";

  export default {
    components: { Header, StyleOne, FooterOne, OurLatestNews, Drawer },
    props: ["one_data","id"],
    data: () => ({
      Posts: Posts.data,
      BigVideoCardWithDes: BigVideoCardWithDes.data,
      sidebar: false,
      /////////////////
      this_data_id: "",
      match1: "",
      match2: "",
      //////////
      this_data: "",
    }),
    computed: {
      filteredBigVideoCards() {
        return this.BigVideoCardWithDes.filter(item => item._id === this.match2);
      }
    },
    watch: {
      '$route'() {
        this.updateFilteredCards();
        this.reloadPage();
      }
    },
    mounted() {
    fetch("../Data/BigVideoCardWithDes.json")
      .then(response => response.json())
      .then(data => {
        this.item = data.find(item => item._id === this.match2);
        console.log(this.item);

      })
      .catch(error => {
        console.error('Error fetching JSON:', error);
      });
  },
    created() {

      document.addEventListener("scroll", this.topToBottom);
      this.this_data_id = window.location.href;
      console.log(this.this_data_id);

      const regex = /[^\\/]+$/;
      this.match = regex.exec(this.this_data_id);
      this.match2 = this.match.toString();
      console.log(this.match2);

      if (!this.this_data) {
      // If one_data is not passed via state, you might want to fetch it using the ID
      //const articleId = this.$route.params.id;
      console.log("calling from xx.");
      // Example: fetch the article by ID
      // this.fetchArticleById(articleId);

    }
    },

    methods: {
      toggleSidebar() {
        this.sidebar = !this.sidebar;
      },
      topToBottom() {
        const result = document.querySelector(".go-top");
        if (
          document.body.scrollTop > window.innerHeight ||
          document.documentElement.scrollTop > window.innerHeight
        ) {
          result.classList.add("active");
        } else {
          result.classList.remove("active");
        }
      },
      updateFilteredCards() {
        this.match2 = this.$route.params.id || '';  // Update match2 based on route parameter
        console.log("neww----------thing" + this.match2);
      },
      reloadPage() {
        window.location.reload();
        console.log("reload page?");
      },
      redirectToLink(link) {
        window.open(link, '_blank');
      },

      convertExclamationsToLink(paragraph, aff_link) {
        const exclamationsPattern = /!!!(.*?)!!!/g;

        // Replace text enclosed by "!!!" with the link
        return paragraph.replace(exclamationsPattern, (match, p1) => {
          return `<a href="${aff_link}" target="_blank" style="color: #12777f; text-decoration: underline;">${p1}</a>`;
        });
      },
      getParagraphContent(section) {
        // Check if aff_link is blank or empty
        if (!section.aff_link) {
          return section.paragraph;
        }
        
        // Check and transform the paragraph content if necessary
        return this.convertExclamationsToLink(section.paragraph, section.aff_link);
      }

    },

    
  };
  
  </script>
  
<style>
.session-img {
  width: 400px; /* Set the desired width */
  height: 200px; /* Set the desired height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure overflow is hidden to prevent any unwanted overflow */
}

.session-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scale the image to cover the container while maintaining aspect ratio */
}

.aff_link{
  color: #12777f ; 
  font-weight: bold;
  text-decoration: none; /* Remove default underline */
  cursor: default; /* Default cursor */
}
.aff_link:hover {
  text-decoration: underline; /* Add underline on hover */
  cursor: pointer; /* Change cursor to pointer on hover */
}



.p_image_w_txt {
  position: relative;
  border: 5px solid #12777f;
  
}

.p_image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  width: 100%; /* Ensure the anchor tag takes full width */
    height: 200px; /* Set a fixed height for the images */
    display: flex;
    justify-content: center;
    object-fit: contain;
    background-color: #12777f;
}

.redirect_txt {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor:pointer;
}

.text {
  background-color: #12777f;
      color: white;
      font-size: 16px;
      line-height: 1.5;
      padding: 16px 24px;
      cursor: pointer;
      margin: 16px 0;
      border-radius: 8px;
}

.p_image_w_txt:hover .redirect_txt {
  opacity: 1;
}


</style>
  