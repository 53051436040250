import { createApp } from 'vue'; // Ensure this is imported from Vue 3
import App from './App.vue';
import router from './router';
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/font-awesome.min.css';
import '../src/assets/css/magnific-popup.css';
import '../src/assets/css/default.css';
import '../src/assets/css/style.css';
import '../src/assets/css/custom.css';
import { store } from './store/index'; // Ensure this is properly exported in your store file
import VueGtag from "vue-gtag-next";
import { initGtag } from './gtag';
import trackClick from './mixins/trackClick.js';
import adsensePlugin from './adsense';
// Create the Vue app instance
const app = createApp(App);

// https://matteo-gabriele.gitbook.io/vue-gtag/v/next
app.use(store);
app.use(router);
app.use(VueGtag, {
  property: {
    id: "G-KT1GL23D82"
  }
}, router);

initGtag();
// Mount the app

app.mixin(trackClick);


app.use(adsensePlugin);

app.mount('#app');

router.afterEach((to, from) => {
  app.config.globalProperties.$trackPageView(to);
});

// Vue 2 code commented out
/*
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
*/
