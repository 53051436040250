<template>
  <div class="post__gallery__area">
    <div class="container">
      <div class="row">
        
        <!--rolling main pic in HOME PAGE-->
        <div class="col-lg-8">
          <div class="post_gallery_slider">
            <Carousel :items-to-show="1" :autoplay="2000" :wrap-around="true" ref="BigCards">
              <Slide v-for="(single, index) in topTen_PostGallery" :key="index">
                <div class="carousel__item"> <big-video-card-with-des :datas="single"  :id="index" /> </div>
              </Slide>

              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>

          <!--rolling small pic in HOME PAGE-->
          <div class="post_gallery_inner_slider" style="position: relative">
            <span
              @click="postGalleryPrev"
              class="prev slick-arrow"
              style="display: block"
              
              ><i class="fal fa-angle-left"></i
            ></span>
            <!--https://ismail9k.github.io/vue3-carousel/examples.html#autoplay-example-->
            <Carousel :autoplay="2000" v-bind="settings"  :wrap-around="true" :breakpoints="breakpoints" ref="miniCards">
              <Slide v-for="(multi, index) in postGallery" :key="index">
                <div class="carousel__item">
                  <div class="item" >
                    <img
                      :src="
                        require(`@/assets/images/${multi.picture}`)
                      "
                      alt=""
                    />
                  </div>
                </div>
              </Slide>
            </Carousel>
            <span
              @click="postGalleryNext"
              class="next slick-arrow"
              style="display: block"
              ><i class="fal fa-angle-right"></i
            ></span>
          </div>
        </div>


        <div class="col-lg-4">
          <home-one
            :trendingShortPost="false"
            :signup="false"
            :trendingBigPost="false"
            :ad="false"
            :sharePost="false"
            :darkClass="darkClass"
            role="sidebar"
            :datas="smallPostGallery"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import postGallery from "../Data/BigVideoCardWithDes";
import Slider from "../Helpers/Slider.vue";
import smallPostGallery from "../Data/NewsRowCard";
import BigVideoCardWithDes from "../Utility/Cards/BigVideoCardWithDes.vue";
import HomeOne from "../Utility/Sidebar/StyleOne.vue";
// import ElectronicIntro from "../ElectronicIntro/ElectronicIntro.vue";
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  components: { Slider, BigVideoCardWithDes, HomeOne, Carousel,Slide,Pagination},
  props: {
    darkClass: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    //post gallery
    postGallery: postGallery.data,
    smallPostGallery: smallPostGallery.data,
    selectedGallery: "trendy",
    miniCardsSettings: {
      slidesToShow: 8,
      slideToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slideToScroll: 1,
            autoplay: true,
          },
        },
      ],
    },
    BigCardsSettings: {
      slidesToShow: 1,
      slideToScroll: 1,
      autoplay: true,
      arrows: false,
    },
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 5.5,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 7,
        snapAlign: 'start',
      },
    },
  }),
  computed: {
    topTen_PostGallery() {
      return this.postGallery.slice(-10);
    },
  },
  methods: {
    //post gallery
    postGalleryPrev() {
      this.$refs.BigCards.prev();
      this.$refs.miniCards.prev();
    },
    postGalleryNext() {
      this.$refs.BigCards.next();
      this.$refs.miniCards.next();
    },
    selectGalleryTab(value) {
      this.selectedGallery = value;
    },
  }
  
};


</script>

<style></style>
