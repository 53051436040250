<template>
  <Archive />
</template>

<script>
import Archive from "../components/Archive/index";
export default {
  components: { Archive },
};
</script>

<style></style>
