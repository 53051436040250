
import { createStore } from 'vuex';

const store = createStore({
    state: {
      // define your state here
      videoPopup:false
    },
    mutations: {
      // define your mutations here
      SET_VALUE_VIDEO:(state, payload)=> {
        console.log(payload)
        state.videoPopup = payload
        
        }
    },
    actions: {
      // define your actions here
      toggleVideo({state,commit }) {
        commit('SET_VALUE_VIDEO',!state.videoPopup)
        }
    },
    modules: {
      // if you have modules
    }
});

export { store };


/*
export const store = new Vuex.Store({
    state: {
        videoPopup:false
    },
    mutations: {
        SET_VALUE_VIDEO:(state, payload)=> {
            console.log(payload)
            state.videoPopup = payload
            
        }
    },
    actions: {
        toggleVideo({state,commit }) {
            commit('SET_VALUE_VIDEO',!state.videoPopup)
        }
    }

})
*/