<template>
  <div class="trending-social">
    <div :class="['section-title', dark ? 'section-title-2' : '']">
      <h3 class="title">Follow us (Comming Soon)</h3>
    </div>
    <ul>
      <li>
        <a href="https://www.facebook.com/profile.php?id=61561344626276" target="_blank" class="sm_icon">
          <i class="fab fa-facebook"></i>
        </a>
      </li>
      <li>
        <a class="item-2">
          
        </a>
      </li>
      <li>
        <a class="item-3" >
          
        </a>
      </li>
    </ul>
    <ul>
      <li>
        <a class="item-4" >
          
        </a>
      </li>
      <li>
        <a class="item-5">
         
        </a>
      </li>
      <li>
        <a class="item-6">
          
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.sm_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #3b5998; /* Facebook blue color */
  }
</style>
