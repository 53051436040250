 <template>
  <section class="all-post-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <entertainment-news v-if="!dark" />
          <entertainment-news
            v-else
            :darkClass="{
              item: 'trending-news-item-dark',
              title: 'section-title-2',
            }"
          />

          <div class="post-add mt-30 resize_banner">
            <a href="#"
              ><img src="@/assets/images/ai_author/ai_image/image_02.jpg" alt="ad" class="resize_banner"
            /></a>
          </div>

          <sports-news v-if="!dark" />
          <sports-news
            v-else
            :darkClass="{
              item: 'trending-news-item-dark',
              title: 'section-title-2',
              news_item: 'gallery_item_dark',
            }"
          />
          <div class="post-add mt-30 resize_banner">
            <a href="#"
              ><img src="@/assets/images/ai_author/ai_image/image_02.jpg" alt="ad" class="resize_banner"
            /></a>
          </div>
          <buisness-news class="pt-40" v-if="!dark" />
          <buisness-news
            v-else
            class="pt-40"
            :darkClass="{
              item: 'business-post-item-dark',
              title: 'section-title-2',
            }"
          />
        </div>
        <div class="col-lg-4">
          <div class="all-post-sidebar">
            <share-post v-if="!dark" />
            <share-post
              v-else
              :darkClass="{
                title: 'section-title-2',
                item: 'most-share-post-item-dark',
              }"
            />
            
            <div class="sidebar-add pt-35">
                <a href="#"
                  ><img src="@/assets/images/ai_author/ai_image/image_05.webp" alt="ad"
                /></a>
              </div>
            <!--Home PAge Catrgoey Grey Area-->
            <div class="Categories-post mt-40">
              <div
                :class="[
                  'section-title d-flex justify-content-between align-items-center',
                  dark ? 'section-title-2' : '',
                ]"
              >
                <h3 class="title">Categories</h3>
                
              </div>
              <div class="Categories-item">
                <div class="item">
                  <img
                    src="@/assets/images/categories-1.jpg"
                    alt="categories"
                  />
                  <div class="Categories-content">
                    <a href="#">
                      <span>All Products</span>
                      <img src="@/assets/images/arrow.svg" alt="" />
                    </a>
                  </div>
                </div>
                <div class="item">
                  <img
                    src="@/assets/images/categories-2.jpg"
                    alt="categories"
                  />
                  <div class="Categories-content">
                    <a href="#">
                      <span>Electronic</span>
                      <img src="@/assets/images/arrow.svg" alt="" />
                    </a>
                  </div>
                </div>

                <div class="item">
                  <img
                    src="@/assets/images/ai_author/ai_image/image_08.webp"
                    alt="categories"
                    class="fill_green"
                  />
                </div>

                <div class="item">
                  <img
                    src="@/assets/images/ai_author/ai_image/image_08.webp"
                    alt="categories"
                    class="fill_green"
                  />
                </div>

              </div>
              <div class="sidebar-add pt-35">
                <a href="#"
                  ><img src="@/assets/images/ai_author/ai_image/image_07.webp" alt="ad"
                /></a>
              </div>

              <div class="sidebar-add pt-35">
                <a href="#"
                  ><img src="@/assets/images/ai_author/ai_image/image_03.webp" alt="ad"
                /></a>
              </div>

              <div class="sidebar-add pt-35">
                <a href="#"
                  ><img src="@/assets/images/ai_author/ai_image/image_06.webp" alt="ad"
                /></a>
              </div>

              <AdSenseSquare1 />

              <AdSenseSquare1 />

              <AdSenseSquare1 />

              <AdSenseSquare1 />

              <AdSenseSquare1 />
              
            </div>
          </div>
        </div>

        
      </div>
    </div>
  </section>
</template>

<script>
import BuisnessNews from "../Home/BuisnessNews.vue";
import EntertainmentNews from "../Home/EntertainmentNews.vue";
import SharePost from "../Home/SharePost.vue";
import SportsNews from "../Home/SportsNews.vue";
import AdSenseSquare1 from '../AdSense/AdSenseSquare1.vue';
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  components: { EntertainmentNews, SportsNews, BuisnessNews, SharePost, AdSenseSquare1 },
};
</script>

<style>
.resize_banner{
  width:728px; /* you can use % */
  height: 90px;
  display: block;
  width:auto; /* you can use % */
  height: auto;
}
.fill_green{
  
}
</style>