// src/gtag.js

export const initGtag = () => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-KT1GL23D82');
    }
  };
  
  export const gtagEvent = (action, category, label, value) => {
    gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  };