<template>
  <homeTwoFour />
</template>

<script>
import homeTwoFour from "../components/HomeTwo/_v4/index";
export default {
  components: { homeTwoFour },
};
</script>

<style>
</style>