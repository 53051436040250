<template>
  <section class="single-play-post-area mt-10">
    <div class="container custom-container">
      <div class="single-play-box">
        <div class="single-play-post-slider position-relative">
          
          <!--HOME PAGE-->
          <Carousel :items-to-show="2.5" :wrap-around="true" ref="BigVideoCardWithDes">
            <Slide v-for="(data, index) in BigVideoCardWithDes" :key="index">
              <div  class="px-3 carousel__item">
                <overlay-card :datas="data" stype="big" />
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
          
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import playPostDatas from "../Data/singlePlay";
import Slider from "../Helpers/Slider.vue";
import OverlayCard from "../Utility/Cards/OverlayCard.vue";
import BigVideoCardWithDes from "../Data/BigVideoCardWithDes";
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
export default {
  components: { OverlayCard, Slider, Carousel,Slide,Navigation },
  data: () => ({
    //playPost
    //playPostDatas: playPostDatas.data,
    playPostSettings: {
      arrows: false,
      slidesToShow: 2,
      slideToScroll: 1,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slideToScroll: 1,
          },
        },
      ],
    },
    BigVideoCardWithDes: BigVideoCardWithDes.data
  }),
  methods: {
    //playPost
    playSliderPrev() {
      this.$refs.playPost.prev();
    },
    playSliderNext() {
      this.$refs.playPost.next();
    },
  },
};
</script>

<style></style>
