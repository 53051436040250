<template>
  <!--FOOTER-->
  <div>
    <footer class="footer-area">
      <div class="container">
        <div class="footer-topbar">
          <div class="row align-items-center">
            <div class="col-lg-7 col-md-5">
              <div class="footer-logo">
                <a ><img src="@/assets/images/nft_3.png" alt="" /></a>
                
              </div>
            </div>
            <div class="col-lg-5 col-md-7">
              
            </div>
          </div>
        </div>
        <div class="footer-widget-area">
          <div class="row">
            <div class="col-lg-8">
              <div class="footer-widget-right-border">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="widget widget-list">
                      <div class="section-title section-title-2">
                        <h3 class="title">NextFutureTech</h3>
                      </div>
                      <div class="list d-flex justify-content-between">
                        <ul>
                          <li><a href="/trending">All Products</a></li>
                         
                        </ul>
                        <ul>
                          <li><a href="/about-us">About</a></li>
                         
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  <!---->
                  
                </div>
                
              </div>

              
            </div>

            
            
          </div>
        </div>
      </div>
    </footer>
    <div class="footer-copyright">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div
              class="
                copyright-item
                d-block d-md-flex
                justify-content-between
                align-items-center
              "
            >
              <p>© Copyright 2024, All Rights Reserved</p>
              <ul>
                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
