<template>
  <div>
    <footer class="footer-area footer-dark">
      <div class="container">
        <div class="footer-topbar">
          <div class="row align-items-center">
            <div class="col-lg-7 col-md-5">
              <div class="footer-logo">
                <a href="#"><img src="@/assets/images/logo-2.png" alt="" /></a>
                <ul>
                  <li>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-youtube"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fab fa-instagram"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-7">
              <div class="footer-newaletter">
                <div class="input-box">
                  <input type="text" placeholder="Your email address" />
                  <button type="button">SIGN UP</button>
                </div>
                <p>We hate spam as much as you do</p>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-widget-area">
          <div class="row">
            <div class="col-lg-8">
              <div class="footer-widget-right-border">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="widget widget-list">
                      <div class="section-title section-title-2">
                        <h3 class="title">News categories</h3>
                      </div>
                      <div class="list d-flex justify-content-between">
                        <ul>
                          <li><a href="#">Politics</a></li>
                          <li><a href="#">Business</a></li>
                          <li><a href="#">Technology</a></li>
                          <li><a href="#">Science</a></li>
                          <li><a href="#">Health</a></li>
                          <li><a href="#">Sports</a></li>
                          <li><a href="#">Entertainment</a></li>
                        </ul>
                        <ul>
                          <li><a href="#">Education</a></li>
                          <li><a href="#">Obituaries</a></li>
                          <li><a href="#">Corrections</a></li>
                          <li><a href="#">Education</a></li>
                          <li><a href="#">Today’s Paper</a></li>
                          <li><a href="#">Corrections</a></li>
                          <li><a href="#">Foods</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="widget widget-list widget-list-2">
                      <div class="section-title section-title-2">
                        <h3 class="title">Living</h3>
                      </div>
                      <div class="list d-flex justify-content-between">
                        <ul>
                          <li><a href="#">Crossword</a></li>
                          <li><a href="#">Food</a></li>
                          <li><a href="#">Automobiles</a></li>
                          <li><a href="#">Education</a></li>
                          <li><a href="#">Health</a></li>
                          <li><a href="#">Magazine</a></li>
                          <li><a href="#">Weddings</a></li>
                        </ul>
                        <ul>
                          <li><a href="#">Classifieds</a></li>
                          <li><a href="#">Photographies</a></li>
                          <li><a href="#">NYT Store</a></li>
                          <li><a href="#">Journalisms</a></li>
                          <li><a href="#">Public Editor</a></li>
                          <li><a href="#">Tools & Services</a></li>
                          <li><a href="#">My Account</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="footer-twitter-post">
                  <div class="row">
                    <div class="col-lg-5 col-md-5">
                      <div class="widget widget-list">
                        <div class="section-title section-title-2">
                          <h3 class="title">Opinion</h3>
                        </div>
                        <div class="list">
                          <ul>
                            <li><a href="#">Today’s Opinion</a></li>
                            <li><a href="#">Op-Ed Contributing</a></li>
                            <li><a href="#">Contributing Writers</a></li>
                            <li><a href="#">Business News</a></li>
                            <li><a href="#">Collections</a></li>
                            <li><a href="#">Today’s Paper</a></li>
                            <li><a href="#">Saturday Review</a></li>
                            <li><a href="#">Product Review</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-7 col-md-7">
                      <div class="widget widget-twitter-post">
                        <div class="twitter-post">
                          <div class="icon">
                            <i class="fab fa-twitter"></i>
                          </div>
                          <div class="twitter-content">
                            <p>
                              Cyber Monday Sale, Save 33% on Jannah theme during
                              our year-end Sale, Purchase a new license for your
                              next project…
                              <a href="#"
                                >@newspark #technology
                                https://dribbble.com/subash_chandra</a
                              >
                            </p>
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                        <div class="twitter-post">
                          <div class="icon">
                            <i class="fab fa-twitter"></i>
                          </div>
                          <div class="twitter-content">
                            <p>
                              Cyber Monday Sale, Save 33% on Jannah theme during
                              our year-end Sale, Purchase a new license for your
                              next project…
                              <a href="#"
                                >@newspark #technology
                                https://dribbble.com/subash_chandra</a
                              >
                            </p>
                            <span>March 26, 2020</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="footer-rightbar mt-60">
                <div class="row">
                  <div class="col-lg-12 col-md-6">
                    <div class="widget widget-news">
                      <div class="section-title section-title-2">
                        <h3 class="title">News categories</h3>
                      </div>
                      <div class="footer-news">
                        <div
                          class="
                            gallery_item gallery_item-style-2
                            footer_gallery_item
                          "
                        >
                          <div class="gallery_item_content">
                            <div class="post-meta">
                              <div class="meta-categories">
                                <a href="#" tabindex="0">TECHNOLOGY</a>
                              </div>
                              <div class="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 class="title">
                              <a href="#" tabindex="0"
                                >Nancy zhang a chinese busy woman and dhaka</a
                              >
                            </h4>
                            <span>1</span>
                          </div>
                        </div>
                        <div
                          class="
                            gallery_item gallery_item-style-2
                            footer_gallery_item
                          "
                        >
                          <div class="gallery_item_content">
                            <div class="post-meta">
                              <div class="meta-categories">
                                <a href="#" tabindex="0">TECHNOLOGY</a>
                              </div>
                              <div class="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 class="title">
                              <a href="#" tabindex="0"
                                >Success is not a good teacher failure makes you
                                humble</a
                              >
                            </h4>
                            <span>2</span>
                          </div>
                        </div>
                        <div
                          class="
                            gallery_item gallery_item-style-2
                            footer_gallery_item
                          "
                        >
                          <div class="gallery_item_content">
                            <div class="post-meta">
                              <div class="meta-categories">
                                <a href="#" tabindex="0">TECHNOLOGY</a>
                              </div>
                              <div class="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 class="title">
                              <a href="#" tabindex="0"
                                >Cheap smartphone sensor could help you old food
                                safe</a
                              >
                            </h4>
                            <span>3</span>
                          </div>
                        </div>
                        <div
                          class="
                            gallery_item gallery_item-style-2
                            footer_gallery_item
                          "
                        >
                          <div class="gallery_item_content">
                            <div class="post-meta">
                              <div class="meta-categories">
                                <a href="#" tabindex="0">TECHNOLOGY</a>
                              </div>
                              <div class="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 class="title">
                              <a href="#" tabindex="0"
                                >Class property employ ancho red multi humble</a
                              >
                            </h4>
                            <span>4</span>
                          </div>
                        </div>
                        <div
                          class="
                            gallery_item gallery_item-style-2
                            footer_gallery_item
                          "
                        >
                          <div class="gallery_item_content">
                            <div class="post-meta">
                              <div class="meta-categories">
                                <a href="#" tabindex="0">TECHNOLOGY</a>
                              </div>
                              <div class="meta-date">
                                <span>March 26, 2020</span>
                              </div>
                            </div>
                            <h4 class="title">
                              <a href="#" tabindex="0"
                                >Best garden wing supplies for the horticu
                                ltural</a
                              >
                            </h4>
                            <span>5</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <div class="widget wedget-service">
                      <div class="section-title section-title-2">
                        <h3 class="title">Newspark news services</h3>
                      </div>
                      <div class="service-item">
                        <ul>
                          <li>
                            <a href="#"
                              ><i class="fal fa-mobile-android-alt"></i> On your
                              mobile</a
                            >
                          </li>
                          <li>
                            <a href="#"
                              ><i class="fal fa-microphone-alt"></i> On smart
                              speakers</a
                            >
                          </li>
                          <li>
                            <a href="#"
                              ><i class="fal fa-envelope"></i> Contact Newspark
                              news</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="footer-copyright footer-copyright-dark">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div
              class="
                copyright-item
                d-block d-md-flex
                justify-content-between
                align-items-center
              "
            >
              <p>© Copyright 2020, All Rights Reserved</p>
              <ul>
                <li><a href="#">About</a></li>
                <li><a href="#">Advertise</a></li>
                <li><a href="#">Privacy & Policy</a></li>
                <li><a href="#">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>