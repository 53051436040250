<template>
  <DarkThree />
</template>

<script>
import DarkThree from "../components/HomeDark/_v3/index";
export default {
  components: { DarkThree },
};
</script>

<style>
</style>