<template>
  <!--3. Trending Articles-->
  <div v-if="stype === 'row'" class="row">
    <div class="col-lg-6 col-md-6">
      <div class="business-post-thumb ">
        <!--cannot scale image-->
        <img v-if="datas.thumbnail && datas.thumbnail !== ''" :src="require(`@/assets/images/` + datas.thumbnail)" alt="feature" class="scaled_image"/>
      </div>
    </div>
    <div class="col-lg-6 col-md-6">
      <div class="trending-news-item">
        <div class="trending-news-content">
          <div class="post-meta">
            <div class="meta-categories">
              
            </div>
            <div class="meta-date">
              
            </div>
          </div>
          <h3 class="title">
            <router-link :to="{name: 'ElectronicIntro',params: {id:datas._id}}"  :data-track-path="`/electronic/${datas._id}`">{{ datas.title }}</router-link>
          </h3>
          <p class="text">
            {{ datas.catch_phase }}
          </p>
          <a v-if="readMore" href="#">Read more</a>
        </div>
      </div>
    </div>
  </div>

  <div v-else-if="stype === 'row-style-2'" class="bussiness-post-item">
    <div class="bussiness-post-thumb">
     
      <img v-if="datas.thumbnail && datas.thumbnail !== ''"
        :src="require(`@/assets/images/business-post/${datas.thumbnail}`)"
        alt="business"
      />
    </div>
    <div class="bussiness-post-content">
      <h3 class="title">
        <router-link :to="{name: 'ElectronicIntro',params: {id:datas._id}}"  :data-track-path="`/electronic/${datas._id}`">{{ datas.title }}</router-link>
      </h3>
      <div class="meta-date-link">
        
        <ul>
          <li>
            <a href="#"><i class="fal fa-bookmark"></i></a>
          </li>
          <li>
            <a href="#"><i class="fas fa-share"></i></a>
          </li>
        </ul>
      </div>
      <p>
        {{ datas.title }}
      </p>
      <a href="#"
        >LEARN MORE <img src="@/assets/images/arrow-2.svg" alt=""
      /></a>
    </div>
  </div>

  <div v-else-if="stype === 'col'" class="trending-news-item">
    <div class="trending-news-thumb">
      <img v-if="datas.thumbnail && datas.thumbnail !== ''" :src="require(`@/assets/images/` + datas.thumbnail)" alt="feature" />
      <div v-if="datas.action === 'trending'" class="icon">
        <a href="#"><i class="fas fa-bolt"></i></a>
      </div>
    </div>
    <div class="trending-news-content">
      <div class="post-meta">
        
      </div>
      <h3 class="title">
        <router-link :to="{name: 'ElectronicIntro',params: {id:datas._id}}"  :data-track-path="`/electronic/${datas._id}`">{{ datas.title }}</router-link>
      </h3>
      <p class="text">
        {{ datas.catch_phase }}
      </p>
      <a v-if="readMore" href="#">Read more</a>
    </div>
  </div>

  
  <div v-else-if="stype === 'col-style-2'" class="finance-item">
    <div class="finance-thumb">
      <img v-if="datas.image && datas.image !== ''"
        :src="require(`@/assets/images/finance/${datas.image}`)"
        alt="finance"
      />
      
    </div>
    
  </div>
</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
    },
    readMore: {
      type: Boolean,
      default: false,
    },
    stype: {
      type: String,
    },
  },
};
</script>

<style>
.scaled_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 380px;
  max-height: 260px;
  display: block;
}

.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust line height as needed */
  max-height: calc(1.5em * 3); /* 3 lines * line height */
}
</style>
