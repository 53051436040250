<template>
  <!--subscribe me-->
  <div class="newsletter-box mt-45 color_green">
    <!--
    <h5 class="title">Newsletter</h5>
    <p>
      Your email address will not be this published. Required fields are News
      Today.
    </p>
    <form action="#">
      <div class="input-box">
        <input type="text" placeholder="Your email address" />
        <button type="button">SIGN UP</button>
      </div>
    </form>
    <span>We hate spam as much as you do</span>
    -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
