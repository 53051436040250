<template>
  <category-layout>
    <!--All Articles-->
    <section class="about-item-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about-author-content pt-15"></div>
          </div>

          <div class="col-lg-8">
            <div class="about-tab-btn mt-40">
              <div class="archive-btn">
                <ul>
                  <li>
                    <span>Category: <span>trending</span></span>
                  </li>
                </ul>
              </div>
              <div class="about-post-items ">
                <div class="row">
                  <div
                    v-for="item in SliceBigVideoCardWithDes"
                    :key="item._id"
                    class="col-lg-6 col-md-6"
                  >
                    <!--loop all post first 0-10-->
                    <div class="trending-news-item mb-30">
                      <div class="trending-news-thumb scale_img">
                        <a :href="item.aff_link" target="_blank">
                          <img v-if="item.picture && item.picture !== ''"
                            :src="require(`@/assets/images/${item.picture}`)"
                            alt="trending"
                            class="scale_img"
                          />
                        </a>
                        <div class="icon">
                          <a href="#" tabindex="-1">
                            <i class="fas fa-bolt"></i>
                          </a>
                        </div>
                      </div>
                      <div class="trending-news-content">
                        <div class="post-meta"></div>
                        <h3 class="title">
                          <router-link
                            :to="{ name: 'ElectronicIntro', params: { id: item._id } }"
                            >{{ item.title }}</router-link
                          >
                        </h3>
                        <p class="text">
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                    <!--end for loop-->
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="pagination-item">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li
                          v-for="page in total_pages"
                          :key="page"
                          class="page-item"
                          :class="{ active: current_page === page }"
                        >
                          <a
                            class="page-link"
                            href="#"
                            @click.prevent="setPage(page)"
                            >{{ page }}</a
                          >
                        </li>
                        <li class="page-item">
                          <a
                            class="page-link"
                            href="#"
                            aria-label="Next"
                            @click.prevent="nextPage"
                          >
                            <span aria-hidden="true"
                              ><i class="fas fa-caret-right"></i
                            ></span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <style-one :datas="posts.slice(1, 6)" :sharePost="false" />
          </div>
        </div>
      </div>
    </section>
  </category-layout>
</template>

<script>
import CategoryLayout from "./CategoryLayout.vue";
import Posts from "../Data/TrendingHomeThree";
import StyleOne from "../Utility/Sidebar/StyleOne.vue";
import BigVideoCardWithDes from "../Data/BigVideoCardWithDes";
export default {
  components: { CategoryLayout, StyleOne },
  data: () => ({
    posts: Posts.data,
    BigVideoCardWithDes: BigVideoCardWithDes.data,
    current_page: 1,
    item_per_page: 10,
  }),
  computed: {
    total_pages() {
      return Math.ceil(this.BigVideoCardWithDes.length / this.item_per_page);
    },
    SliceBigVideoCardWithDes() {
      const start = (this.current_page - 1) * this.item_per_page;
      const end = start + this.item_per_page;
      return this.BigVideoCardWithDes.slice(start, end);
    },
  },
  methods: {
    setPage(page) {
      if (page > 0 && page <= this.total_pages) {
        this.current_page = page;
      }
    },
    nextPage() {
      if (this.current_page < this.total_pages) {
        this.current_page++;
      }
    },
    
  },
};
</script>

<style>
.scale_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 350px;
  max-height: 250px;
  display: block;
}
</style>
