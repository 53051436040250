<template>
  <section class="trending-news-area">
    <div class="container">
      <div class="row">
        <!--left hand side-->
        <div class="col-lg-8">
          <div
            :class="[
              'section-title',
              darkClass && 'title' in darkClass ? darkClass.title : '',
            ]"
          >
            <h3 class="title">Trending Article</h3>
          </div>
          <div class="trending-news-slider position-relative">
            <span
              @click="trandingPrev"
              class="prev slick-arrow"
              style="display: block"
              ><i class="fal fa-angle-left"></i
            ></span>
            <Carousel v-bind="settings" :breakpoints="breakpoints"  ref="trendingSlider">
              <Slide v-for="(data, index) in BigVideoCardWithDes" :key="index">
                <div  class="px-2 carousel__item">
                  <divide-card
                    :class="[
                      darkClass && 'item' in darkClass ? darkClass.item : '',
                    ]"
                    stype="col"
                    :datas="data"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>

            <span
              @click="trandingNext"
              class="next slick-arrow"
              style="display: block"
              ><i class="fal fa-angle-right"></i
            ></span>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div
                :class="[
                  'trending-news-post-items',
                  darkClass && 'news_section' in darkClass
                    ? darkClass.news_section
                    : '',
                ]"
              >
              <!--0 to 3 article-->
                <Slide
                  v-for="(small, index) in BigVideoCardWithDes.slice(0, 3)" :key="index"
                >
                  <row-card
                    :class="[
                      darkClass && 'news_item' in darkClass
                        ? darkClass.news_item
                        : '',
                    ]"
                    
                    :trending="true"
                    :datas="small"
                  />
                </Slide>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div
                :class="[
                  'trending-news-post-items',
                  darkClass && 'news_section' in darkClass
                    ? darkClass.news_section
                    : '',
                ]"
              >
              <!--3 to 6 article-->
                <template
                  v-for="(small, index) in BigVideoCardWithDes.slice(3, 6)" :key="index"
                >
                  <row-card
                    :class="[
                      darkClass && 'news_item' in darkClass
                        ? darkClass.news_item
                        : '',
                    ]"
                    
                    :trending="true"
                    :datas="small"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
        <!--right hand side-->
        <div class="col-lg-4">
          <div class="trending-right-sidebar">
            <FollowCount :dark="dark" />
            <div class="trending-most-view mt-25">
              <div :class="['section-title', dark ? 'section-title-2' : '']">
                <!-- <h3 class="title">Most View </h3> -->
              </div>
            </div>
            <!--right hand side-->
            <div class="trending-sidebar-slider position-relative">
              <!-- <span
                @click="tssPrev"
                class="prev slick-arrow"
                style="display: block"
                ><i class="fal fa-angle-left"></i
              ></span> -->
              <Carousel
                v-bind="settings" :breakpoints="breakpoints"
                ref="trendingSidebarSlide"
              >
              
                <div class="post_gallery_items">

                  <Slide v-for="(small, index) in BigVideoCardWithDes.slice(0, 6)" :key="index">
                    <row-card
                      :class="[
                        darkClass && 'news_item' in darkClass
                          ? darkClass.news_item
                          : '',
                      ]"
                      
                      :counting="true"
                      :count="index + 1"
                      :datas="small"
                    />
                  </Slide>

                </div>
                <div class="post_gallery_items">
                  
                  <Slide
                    v-for="(small, index) in BigVideoCardWithDes.slice(0, 6)" :key="index"
                  >
                    <row-card
                      :class="[
                        darkClass && 'news_item' in darkClass
                          ? darkClass.news_item
                          : '',
                      ]"
                      
                      :counting="true"
                      :count="index + 1"
                      :datas="small"
                    />
                  </Slide>

                </div>
              </Carousel>
              <!-- <span 
                @click="tssNext"
                class="next slick-arrow"
                style="display: block"
                ><i class="fal fa-angle-right"></i
              ></span> -->
            </div>
            
            <div class="post-add mt-30 resize_banner">
            <a href="#"
              ><img src="@/assets/images/ai_author/ai_image/image_02.jpg" alt="ad" class="resize_banner"
            /></a>
            </div>

            <div class="post-add mt-30 resize_banner">
            <a href="#"
              ><img src="@/assets/images/ai_author/ai_image/image_02.jpg" alt="ad" class="resize_banner"
            /></a>
            </div>

            <div class="post-add mt-30 resize_banner">
            <a href="#"
              ><img src="@/assets/images/ai_author/ai_image/image_05.webp" alt="ad" class="resize_banner"
            /></a>
            </div>

            <div class="post-add mt-30 resize_banner">
            <a href="#"
              ><img src="@/assets/images/ai_author/ai_image/image_06.webp" alt="ad" class="resize_banner"
            /></a>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Slider from "../Helpers/Slider.vue";
import DivideCard from "../Utility/Cards/DivideCard.vue";
//import TrendingPosts from "../Data/TrendingPost";
import smallPostGallery from "../Data/NewsRowCard";
import RowCard from "../Utility/Cards/RowCard.vue";
import FollowCount from "../Utility/FollowCount/index";
import BigVideoCardWithDes from "../Data/BigVideoCardWithDes";
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
export default {
  components: { Slider, DivideCard, RowCard, FollowCount, Carousel,Slide,Navigation },
  props: {
    darkClass: {
      type: Object,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    
  },
  data: () => ({
    smallPostGallery: smallPostGallery.data,
    //TrendingPosts: TrendingPosts.data,
    trendingSettings: {
      arrows: false,
      slidesToShow: 2,
      slideToScroll: 1,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slideToScroll: 1,
          },
        },
      ],
    },
    trendingSidebarSlide: {
      arrows: false,
      slidesToShow: 1,
      slideToScroll: 1,
    },
    BigVideoCardWithDes: BigVideoCardWithDes.data,
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    },
  }),
  methods: {
    //trending
    trandingPrev() {
      this.$refs.trendingSlider.prev();
    },
    trandingNext() {
      this.$refs.trendingSlider.next();
    },
    tssPrev() {
      this.$refs.trendingSidebarSlide.prev();
    },
    tssNext() {
      this.$refs.trendingSidebarSlide.next();
    },
  },
};
</script>

<style>

</style>
