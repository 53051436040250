<template>
  <section class="video-news-area">
    <div class="container custom-container">
      <div
        :class="[
          'video-news-box',
          darkClass && 'section' in darkClass ? darkClass.section : '',
        ]"
      >
        <div class="row">
          <!--left-hand-->
          <div class="col-lg-8">
            <div class="video-news-post">
              <div class="section-title section-title-2">
                <h3 class="title">About NextFutureTech</h3>
              </div>
              <div
                :class="[
                  'video-news-post-item',
                  darkClass && 'section_2' in darkClass
                    ? darkClass.section_2
                    : '',
                ]"
              >
                <div class="video-news-post-thumb">
                  <img src="@/assets/images/ai_author/ai_image/image_01.jpg" alt="" class="overlay_video_tn" />
                  <div class="play-btn">
                    <a
                      class="video-popup"
                      @click.prevent="$store.dispatch('toggleVideo')"
                      ><i class="fab fa-youtube"></i
                    ></a>
                  </div>
                  
                </div>
                <div class="video-news-post-content">
                  <div class="post-meta">
                    
                  </div>
                  <h3 class="title">
                    
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <!--4.right-hand-->
          <div class="col-lg-4">
            <div class="populer-post">
              <div :class="['section-title', dark ? 'section-title-2' : '']">
                <h3 class="title">Popular</h3>
              </div>
              <div class="trending-sidebar-slider position-relative">
                <span
                  @click="tssPrev"
                  class="prev slick-arrow"
                  style="display: block"
                  ><i class="fal fa-angle-left"></i
                ></span>
                <slider :settings="videoNewsSlide" ref="trendingSidebarSlide">
                  <div class="post_gallery_items">
                    <template
                      v-for="(small, index) in BigVideoCardWithDes.slice(0, 4)" :key="index"
                    >
                      <row-card
                        :class="[
                          'populer_item-style',
                          darkClass && 'news_item' in darkClass
                            ? darkClass.news_item
                            : '',
                        ]"
                        
                        :trending="true"
                        :datas="small"
                      />
                    </template>
                  </div>
                  <div class="post_gallery_items">
                    <template
                      v-for="(small, index) in BigVideoCardWithDes.slice(0, 4)" :key="index"
                    >
                      <row-card
                        :class="[
                          'populer_item-style',
                          darkClass && 'news_item' in darkClass
                            ? darkClass.news_item
                            : '',
                        ]"
                        
                        :trending="true"
                        :datas="small"
                      />
                    </template>
                  </div>
                </slider>
                <span
                  @click="tssNext"
                  class="next slick-arrow"
                  style="display: block"
                  ><i class="fal fa-angle-right"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import smallPostGallery from "../Data/NewsRowCard";
import Slider from "../Helpers/Slider.vue";
import RowCard from "../Utility/Cards/RowCard.vue";
import BigVideoCardWithDes from "../Data/BigVideoCardWithDes";
export default {
  components: { Slider, RowCard },
  props: {
    darkClass: {
      type: Object,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    smallPostGallery: smallPostGallery.data,
    videoNewsSlide: {
      arrows: false,
      slidesToShow: 1,
      slideToScroll: 1,
    },  
    BigVideoCardWithDes: BigVideoCardWithDes.data 
  }),
  methods: {
    tssPrev() {
      this.$refs.trendingSidebarSlide.prev();
    },
    tssNext() {
      this.$refs.trendingSidebarSlide.next();
    },
  },
};
</script>

<style>



.overlay_video_tn {
  
  filter: brightness(70%);
  cursor: pointer;
}
</style>
