<template>
  <!--right+TOP-hand-side-->
  <!--3.Trending Article left down-->
  <router-link  :to="{name: 'ElectronicIntro',params: {id:datas._id}}" v-if="category" class="gallery_item">
    
    
    <template v-if="datas">
      <div class="gallery_item_thumb reszie_tn">
        
        <img
          v-if="datas.thumbnail"
          :src="require(`@/assets/images/${datas.thumbnail}`)"
          alt=""
        />
      </div>
      <div class="gallery_item_content">
        
        <h4 class="title">
          
          <a href="#">{{ datas.title }}</a>
        </h4>
      </div>
    </template>
  </router-link>

  <router-link
    :to="{name: 'ElectronicIntro',params: {id:datas._id}}"
    v-else-if="countingtwo"
    class="post-gallery-style-2 most-view-style-2"
  >
    <div class="post-gallery-thumb reszie_tn">
      <img
        :src="require(`@/assets/images/${datas.thumbnail}`)"
        alt="gallery"
      />
      <span>{{ count }}</span>
    </div>
    <div class="post-gallery-content">
      <h5 class="title">
        <a href="#">{{ datas.title }}</a>
      </h5>
      <div class="meta-post-2-style">
        <div class="meta-post-categores">
          <a href="#">{{ datas.category.name }}</a>
        </div>
        <div class="meta-post-date">
          <span>{{ datas.category.create_At }}</span>
        </div>
      </div>
    </div>
  </router-link>

  <router-link :to="{name: 'ElectronicIntro',params: {id:datas._id}}"  :data-track-path="`/electronic/${datas._id}`" v-else-if="trending" class="gallery_item">
    <div class="gallery_item_thumb reszie_tn">
      <img
        v-if="datas.thumbnail"
        :src="require(`@/assets/images/${datas.thumbnail}`)"
        alt="gallery"
      />
      <div v-if="datas.trending" class="icon"><i class="fas fa-bolt"></i></div>
    </div>
    <div class="gallery_item_content">
      <div class="post-meta">
        
      </div>
      <h4 class="title">
        <a href="#">{{ datas.title }}</a>
      </h4>
      <!-- <span v-if="counting">{{ counting }}</span> -->
    </div>
  </router-link>

  <!--HomePage Most View (Comming Soon)-->
  <router-link
    :to="{name: 'ElectronicIntro',params: {id:datas._id}}"
    v-else-if="counting"
    class="gallery_item gallery_item-style-2"
  >
    <div class="gallery_item_thumb reszie_tn">
      <img :src="require(`@/assets/images/${datas.thumbnail}`)" alt="gallery" />
      <div class="icon"><i class="fas fa-bolt"></i></div>
    </div>
    <div class="gallery_item_content">
      <div class="post-meta">
        
        
      </div>
      <h4 class="title">
        <a href="#">{{ datas.title }}</a>
      </h4>
      <span>{{ count }}</span>
    </div>
  </router-link>

  <router-link :to="{name: 'ElectronicIntro',params: {id:datas._id}}"  :data-track-path="`/electronic/${datas._id}`" v-else-if="sports" class="gallery_item">
    <template v-if="datas">
      
      <div class="gallery_item_thumb reszie_tn">
        <img
          v-if="datas.thumbnail"
          :src="require(`@/assets/images/${datas.thumbnail}`)"
          alt="gallery"
        />
      </div>
      <div class="gallery_item_content">
        <div class="post-meta">
          <div class="meta-categories">
            <a href="#">{{ datas.category.name }}</a>
          </div>
          <div class="meta-date">
            <span>{{ datas.category.create_At }}</span>
          </div>
        </div>
        <h4 class="title">
          <a href="#">{{ datas.title }}</a>
        </h4>
      </div>
    </template>
  </router-link>

  <router-link
    :to="{name: 'ElectronicIntro',params: {id:datas._id}}"
    v-else
    class="single__post d-lg-flex text-center text-lg-left"
  >
    <template v-if="datas">
      <!--thumbnail up up-->
      <div class="post-thumb mb-3 mb-lg-0 reszie_tn">
        <img
          v-if="datas.thumbnail"
          :src="require(`@/assets/images/${datas.thumbnail}`)"
          alt="post"
        />
      </div>
      <div class="post-content">
        <h4 class="title">
          <a href="#" class="line-clumb">{{ datas.title }}</a>
        </h4>
        <p class="line-clumb-one">{{ datas.description }}</p>
      </div>
    </template>
  </router-link>
</template>

<script>
export default {
  props: {
    category: {
      type: Boolean,
    },
    trending: {
      type: Boolean,
    },
    sports: { type: Boolean, default: false },
    datas: {
      type: Object,
    },
    counting: {
      type: Boolean,
    },
    countingtwo: {
      type: Boolean,
    },
    count: {
      type: Number,
    },
  },
  methods:{
    
  }
};
</script>

<style>
.reszie_tn {
  width: 100px; /* desired width */
  height: 77px; /* desired height */
  overflow: hidden; /* hide any overflow */
  position: relative;
}

.reszie_tn img {
  width: 100%; /* make the image fill the container */
  height: auto; /* maintain aspect ratio */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
