<template>
  <div>
    <category-layout>
      <section class="about-item-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="about-author-content pt-15">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="index.html">Home</a>
                    </li>
                    <li class="breadcrumb-item"><a href="#">Category</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Business
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="about-tab-btn mt-40">
                <div class="archive-btn">
                  <ul>
                    <li>
                      <span>Category: <span>Business</span></span>
                    </li>
                  </ul>
                </div>
                <div class="about-post-items">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="business-post-item mb-40">
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="business-post-thumb">
                              <img
                                src="@/assets/images/business-1.jpg"
                                alt="business"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="trending-news-item">
                              <div class="trending-news-content">
                                <div class="post-meta">
                                  <div class="meta-categories">
                                    <a href="#">TECHNOLOGY</a>
                                  </div>
                                  <div class="meta-date">
                                    <span>March 26, 2020</span>
                                  </div>
                                </div>
                                <h3 class="title">
                                  <router-link to="/posts/postOne"
                                    >There may be no consoles in the future ea
                                    exec says</router-link
                                  >
                                </h3>
                                <p class="text">
                                  The property, complete with 30-seat screening
                                  from room, a 100-seat amphitheater and a
                                  swimming pond with sandy shower…
                                </p>
                                <a href="#">Read more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="business-post-item mb-40">
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="business-post-thumb">
                              <img
                                src="@/assets/images/business-2.jpg"
                                alt="business"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="trending-news-item">
                              <div class="trending-news-content">
                                <div class="post-meta">
                                  <div class="meta-categories">
                                    <a href="#">TECHNOLOGY</a>
                                  </div>
                                  <div class="meta-date">
                                    <span>March 26, 2020</span>
                                  </div>
                                </div>
                                <h3 class="title">
                                  <router-link to="/posts/postOne"
                                    >There may be no consoles in the future ea
                                    exec says</router-link
                                  >
                                </h3>
                                <p class="text">
                                  The property, complete with 30-seat screening
                                  from room, a 100-seat amphitheater and a
                                  swimming pond with sandy shower…
                                </p>
                                <a href="#">Read more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="business-post-item mb-40">
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="business-post-thumb">
                              <img
                                src="@/assets/images/business-3.jpg"
                                alt="business"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="trending-news-item">
                              <div class="trending-news-content">
                                <div class="post-meta">
                                  <div class="meta-categories">
                                    <a href="#">TECHNOLOGY</a>
                                  </div>
                                  <div class="meta-date">
                                    <span>March 26, 2020</span>
                                  </div>
                                </div>
                                <h3 class="title">
                                  <router-link to="/posts/postOne"
                                    >There may be no consoles in the future ea
                                    exec says</router-link
                                  >
                                </h3>
                                <p class="text">
                                  The property, complete with 30-seat screening
                                  from room, a 100-seat amphitheater and a
                                  swimming pond with sandy shower…
                                </p>
                                <a href="#">Read more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="business-post-item mb-40">
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="business-post-thumb">
                              <img
                                src="@/assets/images/business-4.jpg"
                                alt="business"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="trending-news-item">
                              <div class="trending-news-content">
                                <div class="post-meta">
                                  <div class="meta-categories">
                                    <a href="#">TECHNOLOGY</a>
                                  </div>
                                  <div class="meta-date">
                                    <span>March 26, 2020</span>
                                  </div>
                                </div>
                                <h3 class="title">
                                  <router-link to="/posts/postOne"
                                    >There may be no consoles in the future ea
                                    exec says</router-link
                                  >
                                </h3>
                                <p class="text">
                                  The property, complete with 30-seat screening
                                  from room, a 100-seat amphitheater and a
                                  swimming pond with sandy shower…
                                </p>
                                <a href="#">Read more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="business-post-item mb-40">
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="business-post-thumb">
                              <img
                                src="@/assets/images/business-1.jpg"
                                alt="business"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="trending-news-item">
                              <div class="trending-news-content">
                                <div class="post-meta">
                                  <div class="meta-categories">
                                    <a href="#">TECHNOLOGY</a>
                                  </div>
                                  <div class="meta-date">
                                    <span>March 26, 2020</span>
                                  </div>
                                </div>
                                <h3 class="title">
                                  <router-link to="/posts/postOne"
                                    >There may be no consoles in the future ea
                                    exec says</router-link
                                  >
                                </h3>
                                <p class="text">
                                  The property, complete with 30-seat screening
                                  from room, a 100-seat amphitheater and a
                                  swimming pond with sandy shower…
                                </p>
                                <a href="#">Read more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="business-post-item mb-40">
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="business-post-thumb">
                              <img
                                src="@/assets/images/business-2.jpg"
                                alt="business"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="trending-news-item">
                              <div class="trending-news-content">
                                <div class="post-meta">
                                  <div class="meta-categories">
                                    <a href="#">TECHNOLOGY</a>
                                  </div>
                                  <div class="meta-date">
                                    <span>March 26, 2020</span>
                                  </div>
                                </div>
                                <h3 class="title">
                                  <router-link to="/posts/postOne"
                                    >There may be no consoles in the future ea
                                    exec says</router-link
                                  >
                                </h3>
                                <p class="text">
                                  The property, complete with 30-seat screening
                                  from room, a 100-seat amphitheater and a
                                  swimming pond with sandy shower…
                                </p>
                                <a href="#">Read more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="business-post-item mb-40">
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="business-post-thumb">
                              <img
                                src="@/assets/images/business-3.jpg"
                                alt="business"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="trending-news-item">
                              <div class="trending-news-content">
                                <div class="post-meta">
                                  <div class="meta-categories">
                                    <a href="#">TECHNOLOGY</a>
                                  </div>
                                  <div class="meta-date">
                                    <span>March 26, 2020</span>
                                  </div>
                                </div>
                                <h3 class="title">
                                  <router-link to="/posts/postOne"
                                    >There may be no consoles in the future ea
                                    exec says</router-link
                                  >
                                </h3>
                                <p class="text">
                                  The property, complete with 30-seat screening
                                  from room, a 100-seat amphitheater and a
                                  swimming pond with sandy shower…
                                </p>
                                <a href="#">Read more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="business-post-item mb-40">
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="business-post-thumb">
                              <img
                                src="@/assets/images/business-4.jpg"
                                alt="business"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="trending-news-item">
                              <div class="trending-news-content">
                                <div class="post-meta">
                                  <div class="meta-categories">
                                    <a href="#">TECHNOLOGY</a>
                                  </div>
                                  <div class="meta-date">
                                    <span>March 26, 2020</span>
                                  </div>
                                </div>
                                <h3 class="title">
                                  <router-link to="/posts/postOne"
                                    >There may be no consoles in the future ea
                                    exec says</router-link
                                  >
                                </h3>
                                <p class="text">
                                  The property, complete with 30-seat screening
                                  from room, a 100-seat amphitheater and a
                                  swimming pond with sandy shower…
                                </p>
                                <a href="#">Read more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="business-post-item mb-40">
                        <div class="row">
                          <div class="col-lg-6 col-md-6">
                            <div class="business-post-thumb">
                              <img
                                src="@/assets/images/business-1.jpg"
                                alt="business"
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="trending-news-item">
                              <div class="trending-news-content">
                                <div class="post-meta">
                                  <div class="meta-categories">
                                    <a href="#">TECHNOLOGY</a>
                                  </div>
                                  <div class="meta-date">
                                    <span>March 26, 2020</span>
                                  </div>
                                </div>
                                <h3 class="title">
                                  <router-link to="/posts/postOne"
                                    >There may be no consoles in the future ea
                                    exec says</router-link
                                  >
                                </h3>
                                <p class="text">
                                  The property, complete with 30-seat screening
                                  from room, a 100-seat amphitheater and a
                                  swimming pond with sandy shower…
                                </p>
                                <a href="#">Read more</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="pagination-item">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item active">
                              <a class="page-link" href="#">01</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">02</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">...</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">50</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true"
                                  ><i class="fas fa-caret-right"></i
                                ></span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <style-one :datas="posts.slice(1, 6)" :sharePost="false" />
            </div>
          </div>
        </div>
      </section>
    </category-layout>
  </div>
</template>

<script>
import StyleOne from "../Utility/Sidebar/StyleOne.vue";
import CategoryLayout from "./CategoryLayout.vue";
import Posts from "../Data/TrendingHomeThree";
export default {
  components: { CategoryLayout, StyleOne },
  data: () => ({
    posts: Posts.data,
  }),
};
</script>

<style></style>
