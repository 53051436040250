<template>
  <!--rowing card up-side-->
  <div class="post-area">
    
    <div class="container">
      
      <div class="post-slider" style="position: relative">
        <!-- <span class="prev slick-arrow" style="display: block"
            ><i class="fal fa-angle-left"></i
          ></span> -->
        <Carousel :autoplay="4000" v-bind="settings2"  :wrap-around="true" ref="postSlider">
          
          <Slide v-for="(data, index) in RandomeSeven_smallPostGallery" :key="index" >
            <div class="carousel__item"> 
              <row-card  :datas="data" /> 
            </div>
          </Slide>

          <template #addons>
            <Navigation />
          </template>
          
        </Carousel>
        <span @click="next" class="next slick-arrow d-lg-block d-none"
          ><i class="fal fa-angle-right"></i
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
//import smallPostGallery from "../Data/NewsRowCard";
import postGallery from "../Data/BigVideoCardWithDes";
import Slider from "../Helpers/Slider.vue";
import RowCard from "../Utility/Cards/RowCard.vue";
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  components: { RowCard, Slider, Carousel,Slide,Pagination },
  data: () => ({
    postGallery: postGallery.data,
    //smallPostGallery: smallPostGallery.data,
    //post area
    settings: {
      autoplay: true,
      slidesToShow: 3,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "40px",
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: "40px",
            slidesToShow: 1,
          },
        },
      ],
    },
    settings2: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 2.5,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    },
  }),
  computed: {
    RandomeSeven_smallPostGallery() {
      for (let i = this.postGallery.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.postGallery[i], this.postGallery[j]] = [this.postGallery[j], this.postGallery[i]];
      }
      // Return the first 7 elements from the shuffled array
      return this.postGallery.slice(0, 7);
    },
  },
  methods: {
    //post area
    next() {
      this.$refs.postSlider.next();
    },
  },
};
</script>

<style></style>
