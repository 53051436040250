<template>
  <div class="about-author-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about-author-content">
            <nav aria-label="breadcrumb">
              <!-- Breadcrumb content if any -->
            </nav>
            <div class="author-content">
              <div class="thumb">
                <img
                  src="@/assets/images/author1.png"
                  alt="Author"
                  style="width: 80px; height: 80px"
                />
              </div>
              <h3 class="title">Zion</h3>
              <ul>
                <li>NextFutureOwner</li>
                <li>
                  <!-- Additional information if any -->
                </li>
              </ul>
              <div class="text">
                <p>
                  Another tough time for me, the door is closed. That's why I created this website to extend my possibility myself. I was living in United States, United Kingdom, and Hong Kong. I want to extend the user shopping experience to your screen. The chance will come eventually. Email zionn951@gmail.com for advertisement.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="video-news-post-thumb">
        <img src="@/assets/images/ai_author/ai_image/image_01.jpg" alt="Video News Thumbnail" class="" />
      </div>

      <div class="row">
        
          <div v-for="item in QNA_Data" :key="item.qna_id" class="qa-section text">
            <div class="question">
              <span class="highlight">Q:</span> {{ item.question }}
            </div>
            <div class="answer">
              <span class="highlight">A:</span> {{ item.answer }}
            </div>
          </div>

        
      </div>

      <div class="news-container">
        <h2>News Headline</h2>
          <div class="news_item">

            <div class="date">07/08/2024</div>
            <div class="news-content">    
              <p>
                New Articles! 
                <a href="/electronic/NFT001" class="color_n_underline" target="_blank">3 Tech Products You Needed For Your Enjoyment</a>
              </p>
            </div>
            

          </div>


          <!-- You can add more news items similarly -->

          <div class="news_item">
            <div class="date">06/20/2024</div>
            <div class="news-content">    
              <p>
                Our website next future tech is borned! The text will be shown better.
              </p>
            </div>
          </div>

          <div class="news_item">
            <div class="date">06/01/2024</div>
            <div class="news-content">    
              <p>
                next future tech Promotion Video is release! check with 
                <a href="https://youtu.be/8JAveFWnXNQ" target="_blank" class="color_n_underline">this link</a> 
                today. Hope Neo will explain more about NextFutureTech
              </p>
            </div>
          </div>
          <!---->
    </div>


    </div>
  </div>
</template>

<script>
import QNA_Data from "../Data/QandA";


export default {
  name: 'QACarousel',
  components: {
    
  },
  data() {
    return {
      QNA_Data: QNA_Data.data,
      
    };
  },
  
};
</script>

<style scoped>
.author-content {
  position: relative;
  padding-left: 130px;
}

.author-content .thumb {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  overflow: hidden;
  height: 115px;
  width: 115px;
  text-align: center;
  line-height: 112px;
  border: 2px solid #e4e5e6;
}

.qa-section {
  margin: 10px 0;
}

.question, .answer {
  margin-bottom: 5px;
}
/*  */
.news-container {
      width: 80%;
      max-width: 800px;
      border: 1px solid #ddd;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
    }
    .news_item {
      display: flex;
      margin-bottom: 10px;
    }
    .date {
      font-size: 14px;
      color: #666;
      margin-right: 10px;
      white-space: nowrap;
    }
    .news-content {
      font-size: 18px;
      color: #333;
      text-align: justify;
      flex: 1;
    }
</style>
