// src/mixins/trackEvents.js

export default {
    methods: {
      trackClick(event) {
        const target = event.target.closest('a[data-track-path]');
        if (target) {
          const path = target.getAttribute('data-track-path');
          console.log(`User clicked on: ${path}`);  // Log the path to the console
          this.$gtag.event('click', {
            event_category: 'Navigation',
            event_label: path,
          });
        }
      },
      trackPageView(to) {
        this.$gtag.pageview({
          page_path: to.fullPath,
          page_title: document.title,
          page_location: window.location.href,
        });
      },
    },
    mounted() {
      this.$el.addEventListener('click', this.trackClick);
    },
    beforeUnmount() {
      this.$el.removeEventListener('click', this.trackClick);
    },
  };
  