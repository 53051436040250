<template>
  <homeThreeFour />
</template>

<script>
import homeThreeFour from "../components/HomeThree/_v4/index";
export default {
  components: { homeThreeFour },
};
</script>

<style>
</style>