<template>
  <div v-if="this.$store.state.videoPopup">
    <div class="mfp-bg mfp-ready" @click.prevent="closePopup"></div>
    <div
      class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
      tabindex="-1"
      style="overflow: hidden auto"
    >
      <div class="mfp-container mfp-s-ready mfp-iframe-holder">
        <div class="mfp-content">
          <div class="mfp-iframe-scaler">
            <button
              title="Close (Esc)"
              type="button"
              class="mfp-close"
              @click.prevent="closePopup"
            >
              ×
            </button>
            <iframe src="https://share.synthesia.io/embeds/videos/5374ecd6-c283-4e6d-bc24-ba272fdc2192" loading="lazy" title="Synthesia video player - Company Intro" allowfullscreen allow="encrypted-media; fullscreen;" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;" ></iframe>
          </div>
        </div>
        <div class="mfp-preloader">Loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closePopup() {
      this.$store.dispatch("toggleVideo");
    },
  },
};
</script>

<style></style>
