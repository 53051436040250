<template>
  <div class="most-share-post-item">
   
    <div class="post-meta">
      <!-- <div class="meta-categories">
        <a href="#">{{ datas.category.name }}</a>
      </div> 
      <div class="meta-date">
        <span>{{ datas.category.create_At }}</span>
      </div>-->
    </div>
    <h3 class="title">
      <router-link :to="{name: 'ElectronicIntro',params: {id:datas._id}}"  :data-track-path="`/electronic/${datas._id}`">{{ datas.title }}</router-link>
    </h3>
    
    <div class="count">
      <span>{{ count }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
    },
    count: {
      type: Number,
    },
  },
};
</script>

<style></style>
