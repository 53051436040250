<template>
  <section class="feature-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div :class="['section-title', dark ? 'section-title-2' : '']">
            <!--HOME PAGE-->
            <h3 class="title">Feature News</h3>
          </div>
        </div>
      </div>
      <div class="feature-post-slider position-relative">
        <span
          @click="featureSliderPrev"
          class="prev slick-arrow"
          style="display: block"
          ><i class="fal fa-angle-left"></i
        ></span>
        <Carousel :itemsToShow="3.95" :wrapAround="true" :transition="500" ref="featureSlider">
          

            <Slide v-for="(data, index) in this.BigVideoCardWithDes.slice(1, 6)" :key="index">
              <div class="px-2 carousel__item" >
                <overlay-card stype="small" :datas="data" />
              </div>
            </Slide>

          
        </Carousel>
        
        <span
          @click="featureSliderNext"
          class="next slick-arrow"
          style="display: block"
          ><i class="fal fa-angle-right"></i
        ></span>
      </div>
    </div>
  </section>
</template>

<script>

import Slider from "../Helpers/Slider.vue";
import OverlayCard from "../Utility/Cards/OverlayCard.vue";
import BigVideoCardWithDes from "../Data/BigVideoCardWithDes";
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'
export default {
  components: { Slider, OverlayCard, Carousel,Slide,Pagination },
  props: {
    dark: {
      type: Boolean,
    },
  },
  data: () => ({
    //feature
    //post: post.data,
    featureSliderSettings: {
      slidesToShow: 4,
      slideToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slideToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slideToScroll: 1,
          },
        },
      ],
    },
    BigVideoCardWithDes: BigVideoCardWithDes.data,
  }),
  methods: {
    //feature
    featureSliderNext() {
      this.$refs.featureSlider.next();
    },
    featureSliderPrev() {
      this.$refs.featureSlider.prev();
    },
  },
};
</script>

<style></style>
